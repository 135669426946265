import styled from 'styled-components';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import LoaderButton from 'components/Button/LoaderButton';
import MainContainer from 'components/Container/MainContainer';
import { ProviderAppShell } from '../ProviderAppShell';
import WarningMessage from '../Messages/Warning';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import InputRadio from 'components/Input/InputRadio';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;
const ChooseMfaOption = ({
  clientId,
  connectionId,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  payrollProvider,
  providerImplementation,
  prevStep,
  handleAuthorizeResponse,
  sandbox,
  appType,
  error,
  setError,
  authorizeLoading,
  setAuthorizeLoading,
  mfaOptions,
  setRequestFinished,
  client,
  implementationHasAssistedBenefits,
  clientName
}: AuthorizeContextValue) => {
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const handleSubmit = async (fieldToValueMap: Record<string, string>) => {
    console.log('handleSubmit', fieldToValueMap);
    setRequestFinished(false);
    setAuthorizeLoading(true);
    setError(null);
    const {
      mfaType,
      display,
      mfaIdentifier
    } = fieldToValueMap;
    try {
      const response = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'CHOOSE_MFA_OPTION',
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits,
        mfaType,
        // both variabled bellow we want to be undefined in case we didn't receive it
        // multivalueform component requires a value, otherwise it won't submit the form, therefore we use a space string
        display: display.trim() || undefined,
        mfaIdentifier: mfaIdentifier.trim() || undefined
      });
      handleAuthorizeResponse(response);
    } catch (err) {
      setRequestFinished(true);
      setAuthorizeLoading(false);
      setError(null);
    }
  };
  const getLabel = (option: {
    mfaType: string;
    display?: string;
  }) => {
    switch (option.mfaType) {
      case 'text_message':
        return `Text a code${option.display ? ` to ${option.display}` : ''}`;
      case 'voice':
        return `Call with a code${option.display ? ` to ${option.display}` : ''}`;
      case 'authenticator_app':
        return `Enter the verification code from your Authenticator app`;
      default:
        return 'Send a code';
      // this is just to lint, we are filtering out unknown options when building the form of mfaOptions
    }
  };
  return <ProviderAppShell error={error} setError={setError} provider={payrollProvider} providerImplementation={providerImplementation} goBack={() => prevStep()} headerText={'Choose how you want to verify your identity'} data-sentry-element="ProviderAppShell" data-sentry-component="ChooseMfaOption" data-sentry-source-file="ChooseMfaOption.tsx">
      <MultiValueForm formLabel="Choose MFA Option" onSubmit={handleSubmit} fieldNames={['display', 'mfaType', 'mfaIdentifier']} render={({
      display,
      mfaType,
      mfaIdentifier
    }) => {
      return <>
              <MainContainer>
                {error && <WarningMessage>{error.message}</WarningMessage>}

                <RadioContainer>
                  {mfaOptions.filter(option => ['text_message', 'voice', 'authenticator_app'].includes(option.mfaType)).map(option => <InputRadio key={option.mfaType} id={option.mfaType} name="mfaOption" value={option.mfaType} onChange={() => {
              display.onChange(option.display ?? ' ');
              mfaType.onChange(option.mfaType);
              mfaIdentifier.onChange(option.mfaIdentifier ?? ' ');
            }} label={getLabel(option)} />)}
                </RadioContainer>
              </MainContainer>
              <LoaderButton color={payrollProvider.primaryColor} isLoading={authorizeLoading} disabled={authorizeLoading} type="submit" text="Continue" />

              <SignInDisclaimer />
            </>;
    }} data-sentry-element="MultiValueForm" data-sentry-source-file="ChooseMfaOption.tsx" />
    </ProviderAppShell>;
};
export default withAuthorizeContext(ChooseMfaOption);