import { track as amplitudeTrack } from '@amplitude/analytics-browser';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import type { Response as ValidateResponse } from '@finch-api/common/dist/internal/connect/validate';
import { getUrlParamsOnLoad } from './params';
import posthog from 'posthog-js';

export enum AnalyticsEventName {
  LaunchedFinchConnect = 'Launched Finch Connect',
  ViewedConnectStep = 'Viewed Connect Step',
  SelectedProvider = 'Selected Provider',
  ClickedSandboxInstructions = 'Clicked Sandbox Instructions',
  RedirectedToOAuthUrl = 'Redirected to OAuth URL',
  AuthRedirected = 'Provider Authorization Redirect',
}

type AnalyticsEventProperties = {
  [AnalyticsEventName.LaunchedFinchConnect]: never;
  [AnalyticsEventName.ViewedConnectStep]: {
    step: string;
    currentBrowserSessionKey: string;
  };
  [AnalyticsEventName.SelectedProvider]: {
    payrollProviderId: string;
    currentBrowserSessionKey: string;
  };
  [AnalyticsEventName.ClickedSandboxInstructions]: never;
  [AnalyticsEventName.RedirectedToOAuthUrl]: {
    oauthUrl: string;
  };
  [AnalyticsEventName.AuthRedirected]: {
    redirectUrl: string;
  };
};

export const identifySession = (opts: {
  sessionKey: string;
  currentBrowserSessionKey: string;
  clientId: string;
  clientName: string;
  session: ValidateResponse['session'];
}) => {
  const {
    sessionKey,
    currentBrowserSessionKey,
    clientId,
    clientName,
    session,
  } = opts;

  datadogRum.setUser({ id: sessionKey });
  Sentry.setUser({ id: sessionKey });
  posthog.identify(sessionKey, {
    clientName,
    sessionKey,
    currentBrowserSessionKey,
    ...session,
  });
  posthog.group('client', clientId, { clientName });
  posthog.group('currentBrowserSessionKey', currentBrowserSessionKey);
};

export const track = <EventName extends AnalyticsEventName>(
  eventName: EventName,
  eventProperties?: AnalyticsEventProperties[EventName],
) => {
  const baseEventProperties = {
    sandbox: getUrlParamsOnLoad().get('sandbox'),
    /** @deprecated Retaining applicationId for backwards compatibility */
    applicationId: getUrlParamsOnLoad().get('client_id'),
    clientId: getUrlParamsOnLoad().get('client_id'),
  };

  const combinedEventProperties = {
    ...baseEventProperties,
    ...(eventProperties ?? {}),
  };

  amplitudeTrack(eventName, combinedEventProperties);
  Sentry.addBreadcrumb({
    category: 'analytics',
    message: eventName,
    data: combinedEventProperties,
  });
  datadogRum.addAction(eventName, combinedEventProperties);

  posthog.capture(eventName, combinedEventProperties);
};
