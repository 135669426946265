import { createContext, useContext } from 'react';
import { Category, Response as AuthorizeResponse, Sandbox, SelectCompany, MfaOption } from '@finch-api/common/dist/internal/connect/authorize';
import { ImplementationDetail, ProviderConfig, Response as ClientResponse, Session } from '@finch-api/common/dist/internal/connect/validate';
import { AppType, ConnectError, StateRedirect, TerminalAuthState } from './types';
export type AuthorizeContextValue = {
  completeAuth: (as: TerminalAuthState) => void;
  appType: AppType;
  setAppType: React.Dispatch<React.SetStateAction<AppType>>;
  authorizeLoading: boolean;
  setAuthorizeLoading: React.Dispatch<React.SetStateAction<boolean>>;
  requestFinished: boolean;
  setRequestFinished: React.Dispatch<React.SetStateAction<boolean>>;
  category: Category;
  setCategory: React.Dispatch<React.SetStateAction<Category>>;
  clientId: string;
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  providerId: string;
  setProviderId: React.Dispatch<React.SetStateAction<string>>;
  clientName?: string;
  setClientName: React.Dispatch<React.SetStateAction<string | undefined>>;
  redirectUri: string;
  setRedirectUri: React.Dispatch<React.SetStateAction<string>>;
  products: string[] | undefined;
  setProductsWrapper: (props: string[]) => string[];
  hasBenefitsProduct: boolean;
  implementationHasAssistedBenefits: boolean;
  setHasBenefitsProduct: React.Dispatch<React.SetStateAction<boolean>>;
  companyName: string | undefined;
  setCompanyName: React.Dispatch<React.SetStateAction<string | undefined>>;
  contactEmail: string | undefined;
  setContactEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  client: ClientResponse | undefined;
  setClient: React.Dispatch<React.SetStateAction<ClientResponse | undefined>>;
  currentBrowserSessionKey: string;
  setCurrentBrowserSessionKey: React.Dispatch<React.SetStateAction<string>>;
  sessionKey: string;
  setSessionKey: React.Dispatch<React.SetStateAction<string>>;
  state: string | undefined;
  setState: React.Dispatch<React.SetStateAction<string | undefined>>;
  providersConfig: ProviderConfig[] | undefined;
  setProvidersConfig: React.Dispatch<React.SetStateAction<ProviderConfig[] | undefined>>;
  payrollProvider: ProviderConfig | undefined;
  setPayrollProvider: React.Dispatch<React.SetStateAction<ProviderConfig | undefined>>;
  providerImplementation: ImplementationDetail | undefined;
  setProviderImplementation: React.Dispatch<React.SetStateAction<ImplementationDetail | undefined>>;
  manual: boolean;
  setManual: React.Dispatch<React.SetStateAction<boolean>>;
  sandbox: Sandbox;
  setSandbox: React.Dispatch<React.SetStateAction<Sandbox>>;
  phoneNumber: string | undefined;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  nextStep: () => void;
  prevStep: () => void;
  accounts: SelectCompany[];
  setAccounts: React.Dispatch<React.SetStateAction<SelectCompany[]>>;
  handleAuthorizeResponse: (authorizeResponse: AuthorizeResponse) => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
  mfaType: string | undefined;
  setMfaType: React.Dispatch<React.SetStateAction<string | undefined>>;
  challengeQuestion: string | undefined;
  setChallengeQuestion: React.Dispatch<React.SetStateAction<string | undefined>>;
  challengeQuestionRetry: string | boolean | undefined;
  setChallengeQuestionRetry: React.Dispatch<React.SetStateAction<string | boolean | undefined>>;
  email: string | undefined;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  sdkVersion: string | undefined;
  setSdkVersion: React.Dispatch<React.SetStateAction<string | undefined>>;
  postSdkError: (opts: {
    error: ConnectError;
    sdkVersions?: ('v1' | 'v2')[];
  }) => void;
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
  providerToRedirect: string | null;
  setProviderToRedirect: React.Dispatch<React.SetStateAction<string | null>>;
  mfaReason?: string;
  setMfaReason: React.Dispatch<React.SetStateAction<string | undefined>>;
  idpRedirect: boolean;
  setIdpRedirect: React.Dispatch<React.SetStateAction<boolean>>;
  preSetImplementationKind: string | null;
  setPreSetImplementationKind: React.Dispatch<React.SetStateAction<string | null>>;
  connectionId?: string;
  setConnectionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  connection?: Session['connection'];
  setConnection: React.Dispatch<React.SetStateAction<Session['connection'] | undefined>>;
  mfaCode?: string;
  setMfaCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  securityCode?: string;
  setSecurityCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDelayedAuthorizeLoading: (loading: boolean) => void;
  mfaOptions: MfaOption[];
  setMfaOptions: React.Dispatch<React.SetStateAction<MfaOption[]>>;
};
export const AuthorizeContext = createContext<AuthorizeContextValue | null>(null);
export const useAuthorizeContext = () => useContext(AuthorizeContext);
export function withAuthorizeContext(Component: any) {
  return function propsWrapper(props?: any) {
    return <AuthorizeContext.Consumer>
        {value => <Component {...props} {...value} />}
      </AuthorizeContext.Consumer>;
  };
}